import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/swise/1-min.png"
import img1 from "../../../assets/images/portfolio_items/swise/2-min.png"
import img2 from "../../../assets/images/portfolio_items/swise/3-min.png"
import img3 from "../../../assets/images/portfolio_items/swise/4-min.png"
import img4 from "../../../assets/images/portfolio_items/swise/5-min.png"

export default () => 
<div>
    <SEO title={' ICON Worldwide | Swise - Blockchain Project'} 
    description="Swise - blockchain innovation to democratise Private Equity investment: discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/swise'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Swise, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Swise"/>
            <div className="title"><h1>Swise DeFi<span></span></h1>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Swiss + Wise = Swise.  Swiss based start-up Swise uses blockchain innovation to democratise Private Equity investment.  Investments, previously only available to institutional investors, are tokenised on the platform and made available to individual investors.</p>
        <div className="portfolio-images">
            <img src={img1} alt=" Swise, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Swise"/>
            <img src={img2} alt=" Swise, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Swise"/>
            <img src={img3} alt=" Swise, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Swise"/>
        </div>
        <img className="full-img desktop-img" src={img4} alt=" Swise, ICON Worldwide portfolio" title="ICON Worldwide portfolio, Swise"/>
        <WorkFooter previous="frankfurt-school" next="lutheran"/>
        </div>
    </div>
</div>